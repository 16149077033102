<template>
  <div class="Issuemessage">
    <div class="top-title">
      <div class="top-text">发布平台信息</div>
    </div>

    <div class="table-tap">
        <ul>
          <li v-for="(item,i) in list" :key="i"> <span @click="tap(item)" :class="classactive == item.id?'active':''">{{item.title}}</span></li>
        </ul>
    </div>

    <!-- 表单 -->
    <div class="Issuemessage-box">
      <div style=" width:100%"> 
          <Fwbs v-model="listpar.content" :isClear="isClear" @change="change"></Fwbs>
      </div>
       

      <el-button type="primary" class="biy-icon" @click="onSubmit">提交</el-button>
    </div>


  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
import Fwbs from '@/components/fwb/Fwb.vue';
export default {
    name: "Issuemessage",
    components: { Fwbs },
  data() {  
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      list:[],

      classactive:1,
      listpar:{
        content:''
      },
 // 富文本的值
      isClear: false,
    };
  },
  created() {
   this.getSong()
  },
  mounted() {
  },

  methods: {
      // 富文本
      change() { 
        console.log(this.listpar.content);
      }, 

    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetchall',
        bizCatalog:'AgreementEntity',
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.list = res.Result;
           this.listpar.title = res.Result[this.classactive-1].title
           this.listpar.content = res.Result[this.classactive-1].content
      },

      tap(e){
       this.classactive = e.id
       this.getSong()
      },

      //弹窗
      onSubmit(){
          this.$confirm('是否确认发布', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.onSubmits()
          }).catch(action => {
            console.log("取消");
          })
      },
      // 发布
    async onSubmits(){
  
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'update',
        bizCatalog:'AgreementEntity',
        bizId:this.classactive,
        title:this.listpar.title,
        content:this.listpar.content
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
        this.$alert('发布成功', '消息提示', {confirmButtonText: '确定'});
      },
  },
  computed: {},
};
</script>


<style  scoped>
.Issuemessage .top-title{
  height: 50px !important;
}

.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 180px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}

.Issuemessage-box{
  width: 1000px;
  height: 600px;
  margin: 0 auto;
  padding-top: 50px
}
.biy-icon{
  display: block;
  width: 300px;
  margin: 0 auto
}

@import "../../assets/css/liststyle.css";
</style>